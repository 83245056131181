/* Input Bar */
.input-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--margin-medium);
  }
  .input-container {
    width: calc(100% - 80px);
    display: flex;
    background-color: white;
    border-radius: 40px;
    padding: 0em 0em;
  }
  
  .input {
    background-color: transparent;
    width: calc(100% - 60px);
    padding: var(--padding-medium);
    padding-left: 1em;
    font-size: 16px;
    border-radius: 40px 0px 0px 40px;
    border: none;
    height: 50px;
  }
  
  .select {
    background-color: #3A3A3A;
    border-radius: 0px 35px 35px 0px;
    margin-right: -1px;
    color: white;
    width: 60px;
    height: 50px;
    border: none;
    border-left: 1px solid black;
    padding: var(--padding-small);
    font-size: 16px;
  }
  
  .submit-button {
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    height: 55px;
    width: 55px;
    border-radius: 100px;
    border: none;
    background-color:#9E2722;
    color: #731D19;
    box-shadow: 5px 5px #731D19, 2px 2px #731D19;
    transition: all 0.2s ease-in-out;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit-button img {
    height: 35px;
  }
  
  .submit-button:hover {
    margin-top: 5px;
    box-shadow: none;
    margin-right: -5px;
    margin-bottom: -5px;
  }

  .errorMsg {
    width: 100%;
    color: rgb(255, 255, 255);
  }

 
