h1 {
    font-family: 'Jacquard 24', sans-serif;
    color: white;
}

h1:hover {
    cursor: pointer;
}

.logoContainer {
    width: 100%;
    max-height: 100px;
}
.logo {
    margin: 0;
    text-align: left;
    width: 100px;
    max-height: 100px;

}

/* Image Generator Layout */
.image-generator-container {
    overflow-x: hidden;
    display: flex;
    height: 100vh;
  }
  
  /* Left Panel */
  .image-generator-container div.left-panel {
    padding: var(--padding-large);
    /* padding-bottom: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 50%;
}

.sidebar-button{
    width: fit-content;
    position: absolute;
    top: calc(var(--padding-large) - 2px);
    right: calc(var(--padding-large) + 50px);
    z-index: 1;
    background-color: transparent;
    border: none;
}
.sidebar-button:hover {
    cursor: pointer;
}

/* Right Panel */
.right-panel {
    position: relative;
    background-color: var(--darker-background);
    width: 50vw;
    padding: var(--padding-large);
    overflow-y: auto;
    min-height: 500px;
}

.right-panel, .left-panel, .image-generator-container {
    transition: all 0.7s ease-in-out;
}

@media only screen and (max-width: 1040px) {
    .right-panel, .left-panel, .image-generator-container {
        transition: none;
    }
    .image-generator-container {
        flex-direction: column;
        height: fit-content;
    }
    .image-generator-container div.left-panel {
        max-width: 100%;
        width: 100%;
        align-items: center;
    }

    .img-and-inputs {
        max-width: 60vw;
        min-width: 523px;
    }
    .image-container-main {
        max-width: 450px;
        max-height: 450px;
    }
    .right-panel {
        width: 100svw;
    }
}

@media only screen and (max-width: 620px) {
    .img-and-inputs {
        max-width: 400px;
        min-width: 400px;
    }
    .image-container-main {
        max-width: 400px;
        max-height: 400px;
    }
}

@media only screen and (max-width: 450px) {
    .img-and-inputs {
        max-width: 300px;
        min-width: 300px;
    }
    .image-container-main {
        max-width:300px;
        max-height: 300px;
    }
}