.ghost-placeholder {
    width: 100%;
    height: 23vw;
    background-color: #e0e0e0;
    border-radius: 10px;
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      background-color: #f0f0f027;
    }
    50% {
      background-color: #e0e0e044;
    }
    100% {
      background-color: #f0f0f027;
    }
  }
  

  .loader {
    animation: rotate 3s infinite;
    height: 50px;
    width: 50px;
  }

  .loader:before,
  .loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }
  .loader:before {
    animation: ball1 3s infinite;
    background-color: #fff;
    box-shadow: 30px 0 0 #717171;
    margin-bottom: 10px;
  }
  .loader:after {
    animation: ball2 3s infinite;
    background-color: #ff3d00;
    box-shadow: 30px 0 0 #fff;
  }

  @keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #ff3d00;
    }
    50% {
      box-shadow: 0 0 0 #ff3d00;
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #ff3d00;
      margin-bottom: 10px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #fff;
    }
    50% {
      box-shadow: 0 0 0 #fff;
      margin-top: -20px;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #fff;
      margin-top: 0;
    }
  }
  



  .spinner {
    animation: spin 3s infinite;
    animation-delay: 0.25s;
  }


  @keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(720deg) }
  }