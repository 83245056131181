.tos, .pp {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.tos p,
.tos a,
.pp p,
.pp a{
    width: 400px;
 color: white;
}