.gradient-background {
    z-index: -1;
    width: 100%;
    height: 120vh;
    filter: blur(4px);
    display: flex;
    height: calc(100vh + 236px);
    position: absolute;
    left: -9px;
    top: -236px;
    background-repeat: no-repeat;
  }

  #gradient-canvas { width:100%; 
    z-index: -1;
    position: absolute;
    height:100%; --gradient-color-1: #bb7506;  
    --gradient-color-2: #0f575e;  
    --gradient-color-3: #cb3443;   
    --gradient-color-4: #16130a; 
}


@media only screen and (max-width: 1040px) {
  #gradient-canvas {
    height: calc(100vh + 236px + var(--padding-small));
  }
}