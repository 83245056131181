/* src/App.css */

/* Variables */
:root {
  --background-color: #282828;
  --darker-background: #121212;
  --primary-color: #007bff;
  --text-color: #ffffff;
  --error-color: red;
  --border-color: #ddd;
  --input-border-color: #ccc;
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --faded-white: #f4f4f411;
  --border-radius: 8px;
  --padding-small: 5px;
  --padding-medium: 10px;
  --padding-large: 20px;
  --margin-medium: 20px;
  --max-width-container: 600px;
}

/* Global Reset */
* {
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: var(--background-color);
}

#root {
  overflow-x: hidden;
}
