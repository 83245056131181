.modal {
    color: white;
}

.modal a {
    font-size: 0.8rem;
    color: white;
    margin: 3em 0.8em;
}

.close-modal-button {
    margin-top: 20px;
    background-color: #4B4B4B;
    padding: 0.5em 1em;
}

.close-modal-button.delete {
    background-color: rgb(196, 6, 6);
}

.deleteModalOptions {
    display: flex;
    justify-content: space-evenly;
}