/* src/styles/EmailVerification.css */
.verification-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.verification-message {
    margin-inline: 50px;
    max-width: 500px;
    background-color: #363636;
    padding: 40px;
    border-radius: 10px;
  }
  
  .email-icon {
    width: 150px;
    margin: 20px 0;
  }
  
  .return-button {
    background-color: #4B4B4B;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .return-button:hover {
    background-color: #575757;
  }
  
.verification-container #gradient-canvas {
    width: 100vw;
    max-width: 100vw;
    position: absolute;
    left: 0;
    height: 100vh;
}