/* Credits Information */
.credits-info p {
    color: var(--text-color);
  }
  
  .credits-info {
    display: flex;
    align-items: center;
  }
  
  .credits-info button {
    height: fit-content;
    padding: var(--padding-small) var(--padding-medium);
    font-size: 16px;
    cursor: pointer;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--primary-color);
    color: white;
    margin-left: var(--padding-small);
  }

  /* Sign-in and Account */

  .account-container {
    position: absolute;
    top: var(--padding-large);
    right: var(--padding-large);
  }
  
  .sign-in-button,
  .account-button {
    padding: var(--padding-small) var(--padding-medium);
    font-size: 16px;
    background-color: #3A3A3A;
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-left: var(--padding-small);
  }
  
/* Account Circle */
.account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}


.account-dropdown-container:hover {
  width: 200px;
}

.account-dropdown-container {
  position: relative;
  z-index: 2;
}

.account-circle {
  right: 0;
  z-index: 31;
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #3A3A3A;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.account-circle img {
  z-index: 2; /* Lower this to stay behind the hovered text */
  position: relative; /* Ensure the image is within the stacking context */
}

.account-circle span {
  text-transform: uppercase;
}

/* Dropdown menu */
.account-dropdown {
  flex-direction: column;
  z-index: 30;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 10;
  background-color: #3A3A3A;
  border-radius: var(--border-radius) 40px var(--border-radius) var(--border-radius) ;
  padding: var(--padding-medium);
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.account-dropdown-container .email {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 25;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  margin: 0px 12px;
  padding: var(--padding-small) var(--padding-medium);
  margin-right: 0;
  padding-right: 0;
  background-color: #3A3A3A;
  color: white;
}

.account-dropdown-container .email:hover {
  cursor: pointer;
  display: block; /* Add this to apply z-index */
  z-index: 40; /* Higher than the image */
  border-radius: 20px;
  box-shadow: 0px 0px 12.4px 0px rgba(0, 0, 0, 0.4);
  width: calc(200px - 10px * 2);
}


.account-dropdown .profile-title {
  margin-bottom: 20px;
}


.account-dropdown>button {
  background-color: #3A3A3A;
  color: white;
  padding: var(--padding-small) var(--padding-medium);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.account-dropdown>* {
  z-index: 55;
}

.account-dropdown>button:hover {
  background-color: #4D4D4D;
}

.account-dropdown>button:last-child {
  margin-top: 10px;
  margin-bottom: 0;
  padding-top: 10px;
  border-top: 1px solid #4D4D4D;
  border-radius: 0 0 8px 8px;
}

.account-dropdown .settings {
  margin-top: 50px;
}
.account-dropdown>button:last-child {
  margin-top: 10px;
}

/* .account-dropdown-container:hover .email {
  left: 0;
} */

.account-container.wide {
  display: block;
}
.account-container.thin {
  display: none;
}
.account-container.thin.closed {
  display: block;
}

@media only screen and (max-width: 1040px) {
  .account-container.wide {
    display: none;
  }
  .account-container.thin {
    display: block;
  }
}