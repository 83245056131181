/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .modal {
    background: #3A3A3A;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  .credit-input {
    width: 80%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .checkout-button {
    background-color: #878787;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .checkout-button:disabled {
    background-color: #ccc;
  }
  
  .close-modal-button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .credits-info .input-error {
    color: red;
    margin-bottom: 10px;
  }
  