
.img-and-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  max-width: 40vw;
}

/* Main Image Container */
.image-container-main {

    background: rgba(53, 53, 53, 0.5);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 40px;
    position: relative;
    /* width: fit-content; */
    
    height: 100%;
    max-height: 55vh;
    max-width: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--margin-medium);
    transition: all 0.2s ease-in-out;
  }
  
  .image-main {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in-out;
  }
  
  .image-wrapper:hover .image-main{
    cursor: pointer;
    filter: brightness(75%)
  }
  
  .image-placeholder {
    font-size: 18px;
    color: var(--text-color);
  }
  
  /* Fullscreen Modal */
  .fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 49;
  }

  
  .modal-content {
    position: relative;
    background-color: white;
    padding: var(--padding-medium);
    border-radius: var(--border-radius);
  }

  .modal-content .download-button {
    right: 60px ;
  }
  
  .fullscreen-image {
    max-width: 90vw;
    max-height: 90vh;
    border-radius: var(--border-radius);
  }
  
  .close-modal {
    position: fixed;
    top: 0;
    right: 0px;
}


.modal-content button {
    position: absolute;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.205);
    color: var(--text-color);
    border: none;
    padding: var(--padding-small);
    cursor: pointer;
    border-radius: var(--border-radius);
  }



.image-wrapper button{
    position: absolute;
    background-color: transparent;
    display: none;
    height: fit-content;
    color: white;
    padding: var(--padding-small) var(--padding-medium);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.2s ease-in-out;
}

.delete-button {
  top: 0px;
  left: 0px;
}

.download-button:hover {
  background-color: rgba(0, 128, 0, 0.295);
}
.download-button {
  top: 0px;
  right: 0;
}

.delete-button:hover {
  background-color: rgba(196, 31, 31, 0.192);
}

.fullscreen-button {
  bottom: 0px;
  right: 0;
}

.fullscreen-button:hover {
  background-color: rgba(10, 85, 235, 0.322);
}

.image-wrapper:hover button {
  display: block;
}

.image-wrapper {
    height: 100%;
    max-height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
}

.image-columns .image-wrapper>img {
  transition: all 0.2s ease-in-out;
}

.image-columns .image-wrapper>img:hover {
  cursor: pointer;
  filter: brightness(75%)
}
