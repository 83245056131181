/* Image Library */

.right-panel>h3 {
    color: var(--text-color);
    font-size: 2rem;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--padding-medium);
}
  
.image-grid-item {
    background-color: var(--faded-white);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-thumbnail {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--border-radius);
}

.image-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--padding-medium);
}

.image-column {
    display: flex;
    gap: var(--padding-medium);
}

.image-column {
    flex-direction: column;
}