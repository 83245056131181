
.sign-in-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.sign-in-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    padding-left: 5em;
    color: var(--text-color);
    background-color: #171717;
    height: 100%;
    width: 50vw;
    max-width: 800px;
}

.sign-in-container h2 {
    font-size: 3rem;
}

.sign-in-container form {
    max-width: 500px;
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: space-evenly;

}

.sign-in-container form input {
    font-size: 1.1em;
    border: none;
    border-radius: 10px;
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.839);
}

.sign-in-container form .sign-in-button {
    padding: 1.1em;
    border-radius: 30px;
}

.sign-in-container a {
    color: var(--text-color);
}

.sign-in-wrapper .teuring-logo{
    margin: 0;
    position: fixed;
    height: 100px;
    left: 45px;
    top: 20px;
}

.error-message {
    color: rgb(234, 40, 40);
}

.google-sign-in-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    padding: 10px 15px;
    margin-top: 30px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #505050;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .google-sign-in-button:hover {
    background-color: #f7f7f7;
  }
  
  .google-sign-in-button img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }


  .policies {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: space-evenly;
}

.policies a {
      color: #9f9f9f;
    
  }

  .policies a:first-child{
    margin-right: 30px;
  }

@media only screen and (max-width: 845px) {
    .sign-in-wrapper #gradient-canvas {
        display: none;
    }
    
    .sign-in-container {
        align-items: center;
        width: 100%;
        max-width: 100vw;
    }
    
    .sign-in-wrapper .teuring-logo {
        height: 100px;
        left: calc(50% - 50px);
    }

    .sign-in-container {
        padding-left: 2em;
    }
}