/* Suggestions */
.suggestions {
  width: 100%;
  display: flex;
  justify-content: center;
    margin-top: var(--margin-medium);
  }
  
  .suggestions-container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    gap: var(--padding-medium);
  }
  
  .suggestion-button {
    background: rgba(53, 53, 53, 0.5);
    padding: 1em;
    color: rgba(255, 255, 255, 0.6);
    border: none;
    backdrop-filter: blur(40px);
    cursor: pointer;
    border-radius: 40px;
    transition: all 0.2s ease-in-out;
  }

  .suggestion-button:hover {
    background-color: rgba(53, 53, 53);
    color: white;
  }
  