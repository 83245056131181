/* Credits Information */
.credits-info p {
    color: var(--text-color);
  }
  
  .credits-info {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  
  .credits-info button {
    height: fit-content;
    padding: var(--padding-small) var(--padding-medium);
    font-size: 16px;
    cursor: pointer;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--primary-color);
    color: white;
    margin-left: var(--padding-small);
  }

  .credits-info .creditCount {
    background-color: #3A3A3A;
    border-radius: 40px;
    padding: 0.5em;
  }

  .credits-info:hover {
    cursor: pointer;
  }

  .creditCount {
    transition: all 0.2s ease-in-out;
  }

  .creditCount::after {
    content: '';
  }
  
  .credits-info:hover .creditCount::after {
    content: ' +';
  }
  
  .creditCount:hover {
    
    cursor: pointer;
  }
  